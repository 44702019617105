import Component from '../core/Component';
import Choices from 'Choices.js'

export default class Select extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            select: null
         }
    }

    prepare() {
        this.polyfillAssign()
        
        const choices = new Choices(this.ref.select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
            placeholder: true
        });

        if (this.element.hasAttribute("data-redirect")) {
            this.element.addEventListener("change",:: this.handleChange)
        }
    }

    handleChange() {
        const url = this.ref.select.options[0].value
        window.location.href = url
    }

    polyfillAssign() {
        if (typeof Object.assign != 'function') {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, "assign", {
                value: function assign(target, varArgs) { // .length of function is 2
                    'use strict';
                    if (target == null) { // TypeError if undefined or null
                        throw new TypeError('Cannot convert undefined or null to object');
                    }

                    var to = Object(target);

                    for (var index = 1; index < arguments.length; index++) {
                        var nextSource = arguments[index];

                        if (nextSource != null) { // Skip over if undefined or null
                            for (var nextKey in nextSource) {
                                // Avoid bugs when hasOwnProperty is shadowed
                                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                    to[nextKey] = nextSource[nextKey];
                                }
                            }
                        }
                    }
                    return to;
                },
                writable: true,
                configurable: true
            });
        }
    }
}